<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup" style="width: 90%; margin: 0 auto;">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Collaborator.Page.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <!-- <input type="hidden" nbInput fullWidth formControlName="Code"> -->
            <div class="row">
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Code" label="Common.code" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'Common.code' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="TagName" label="Common.tagName" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="TagName" placeholder="{{'Common.tagName' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Summary" label="Common.summary" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="Summary" placeholder="{{'Common.summary' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <textarea type="text" class="scrollable-container" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}"></textarea>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Logo" label="Common.logo" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <ngx-file-input formControlName="Logo"></ngx-file-input>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Banner" label="Common.banner" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <ngx-file-input formControlName="Banner"></ngx-file-input>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="PriceTable" label="Sales.priceTable" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <ngx-select2 formControlName="PriceTable" [select2Option]="select2SalesPriceReportOption"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Weekend" label="DateTime.weekend" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <ngx-select2 formControlName="Weekend" [select2Option]="select2OptionForWeekend" [data]="select2OptionForWeekend.data"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="EndOfTheMonth" label="DateTime.endOfTheMonth" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="EndOfTheMonth" placeholder="{{'DateTime.endOfTheMonth' | translate | headtitlecase}}" [inputMask]="endOfMonthInputMask">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="EndOfTheQuarter" label="DateTime.endOfTheQuarter" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="EndOfTheQuarter" placeholder="{{'DateTime.endOfTheQuarter' | translate | headtitlecase}}" [inputMask]="endOfMonthInputMask">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="EndOfTheYear" label="DateTime.endOfTheYear" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="EndOfTheYear" placeholder="{{'DateTime.endOfTheYear' | translate | headtitlecase}}"  [inputMask]="endOfYearInputMask">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="CoreConnection" label="BM One" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <ngx-select2 formControlName="CoreConnection" [select2Option]="select2CoreConnectionOption" [data]="coreConnectionList"></ngx-select2>
                </ngx-form-group>
              </div>
              <!-- <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="EventUrl" label="Common.eventUrl" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="EventUrl" placeholder="{{'Common.eventUrl' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="PlatformApiUrl" label="Common.apiUrl" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="PlatformApiUrl" placeholder="{{'Common.apiUrl' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="PlatformApiToken" label="Common.apiToken" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <textarea type="text" class="scrollable-container" nbInput fullWidth formControlName="PlatformApiToken" placeholder="{{'Common.apiToken' | translate | headtitlecase}}"></textarea>
                </ngx-form-group>
              </div> -->
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>