<ag-grid-angular #agGrid style="width: 100%; height: 300px" id="myGrid" class="ag-theme-balham{{themeName ? ('-'+themeName) : ''}}" [modules]="modules"
  [columnDefs]="columnDefs" [debug]="false" [defaultColDef]="defaultColDef"
  [rowData]="rowData" [immutableData]="immutableData"
  [rowSelection]="rowSelection" [rowModelType]="rowModelType"
  [paginationPageSize]="paginationPageSize" [cacheOverflowSize]="cacheOverflowSize"
  [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
  [infiniteInitialRowCount]="infiniteInitialRowCount" [maxBlocksInCache]="maxBlocksInCache"
  [cacheBlockSize]="cacheBlockSize" [getRowNodeId]="getRowNodeId" [components]="components"
  (gridReady)="onGridReady($event)" [multiSortKey]="multiSortKey" [rowDragManaged]="rowDragManaged"
  [getRowHeight]="getRowHeight" [rowHeight]="rowHeight" (columnResized)="onColumnResized()"
  (rowSelected)="onRowSelected()" [pagination]="pagination">
</ag-grid-angular>
